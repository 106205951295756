<template>

  <section v-if="visible" class="container p-0">
    <div class="row">

      <div class="col-7 p-4 border-right">
        <div v-if="currentNAWStep <= maxNAWStep">
          <p v-show="!isEditing"><b>{{ currentNAWStep }}</b>/{{ maxNAWStep + ' ' + $t('employee.naw') }}</p>
          <h1 v-show="!isEmployeeFillingInNAW || currentNAWStep < maxNAWStep">{{ $t('employee.step_naw_' + currentNAWStep) }}</h1>
        </div>



        <div v-show="isLoading" id="step-0">
          <i class="fa fa-spin fa-spinner"></i>&ensp;{{ $t('form.please_wait') }}
        </div>
        <div v-show="currentNAWStep == 1 && !isLoading" id="step-1">
          <div class="mt-2" v-if="nawData.effective_date !== undefined">
            <label for="startdate">{{ $t('form.effective_date') }}</label>
            <input type="date" id="effdate" v-model="nawData.effective_date" @change="isValid" />
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <label for="postal-code">{{ $t('form.first_name') }}</label>
              <input type="text" id="naw-first-name" v-model="nawData.first_name" @change="isValid" />
            </div>
            <div class="col-2">
              <label for="city">{{ $t('form.prefix_short') }}</label>
              <input type="text" class="optional" id="naw-prefx-name" v-model="nawData.prefix" />
            </div>
            <div class="col-6">
              <label for="city">{{ $t('form.last_name') }}</label>
              <input type="text" id="naw-last-name" v-model="nawData.last_name" @change="isValid" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="street">{{ $t('form.street') }}</label>
              <input type="text" id="street" v-model="nawData.address.street" @change="isValid" />
            </div>
            <div class="col-3">
              <label for="housenr">{{ $t('form.house_nr') }}</label>
              <input type="number" id="housenr" v-model="nawData.address.house_number" @change="isValid" />
            </div>
            <div class="col-3">
              <label for="addition">{{ $t('form.suffix') }}</label>
              <input type="text" id="addition" class="optional" v-model="nawData.address.prefix" />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="postal-code">{{ $t('form.postal_code') }}</label>
              <input type="text" id="postal-code" v-model="nawData.address.postal_code" @change="isValid" />
            </div>
            <div class="col-4">
              <label for="city">{{ $t('form.city') }}</label>
              <input type="text" id="city" v-model="nawData.address.place" @change="isValid" />
            </div>
            <div class="col-4">
              <label for="country2">{{ $t('form.country') }}</label>
              <select id="country2" v-model="nawData.address.country_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="country in countries" :key="country.id" :value="country.id"> {{ country.name }} </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="email-2">{{ $t('form.email') }}</label>
              <input type="email" id="email-2" v-model="nawData.email" @change="isValid" />
            </div>
            <div class="col-4">
              <label for="phone">{{ $t('form.phone') }}</label>
              <input type="tel" id="phone" v-model="nawData.phone_number" @change="isValid" />
            </div>
            <div class="col-4">
              <label for="dob">{{ $t('form.dob') }}</label>
              <input type="date" id="dob" v-model="nawData.birth_date" data-notinfuture="" @change="isValid" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="pob">{{ $t('form.pob') }}</label>
              <input type="text" id="pob" v-model="nawData.birth_place" @change="isValid" />
            </div>
            <div class="col-6">
              <label for="bsn">{{ $t('form.bsn') }}</label>
              <input type="bsn" id="bsn" v-model="nawData.bsn_number" placeholder="000000000" @change="validateBSN($event.target)" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="nationality">{{ $t('form.nationality') }}</label>
              <select id="nationality" v-model="nawData.nationality" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="nationality in nationalities" :key="nationality.id" :value="nationality.id"> {{ nationality.name }} </option>
              </select>
            </div>
            <div class="col-6">
              <label for="iban">{{ $t('form.iban') }}</label>
              <input type="iban" id="iban" v-model="nawData.iban" @change="validateIBAN($event.target)" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="country">{{ $t('form.country_of_birth') }}</label>
              <select id="country" v-model="nawData.birth_country_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="country in countries" :key="country.id" :value="country.id"> {{ country.name }} </option>
              </select>
            </div>
            <div class="col-6">
              <label>{{ $t('form.gender') }}</label>
              <fieldset class="col-sm-4 btn-group w-100" :data-field="nawData.gender">
                <input id='gt1' type="radio" name="gtype" class="btn-check" value="male" v-model="nawData.gender" @change="isValid" />
                <label for="gt1" class="btn btn-sm">{{$t('form.gender_male')}}</label>
                <input id='gt2' type="radio" name="gtype" class="btn-check" value="female" v-model="nawData.gender" @change="isValid" />
                <label for="gt2" class="btn btn-sm">{{$t('form.gender_female')}}</label>
                <input id='gt3' type="radio" name="gtype" class="btn-check" value="undefined" v-model="nawData.gender" @change="isValid" />
                <label for="gt3" class="btn btn-sm">{{$t('form.gender_undefined')}}</label>
              </fieldset>
            </div>
          </div>
        </div>



        <div v-show="currentNAWStep == 2" id="step-2">
          <p class="row"></p>
          <div class="row" v-if="isEditing">
            <label class="col-sm-8">{{ $t('employee.upload_new_files') }}</label>
            <fieldset class="col-sm-4 btn-group" :data-field="nawData.new_identity_files">
              <input id='nidyes' type="radio" name="nidtype" class="btn-check" :value="1" v-model="nawData.new_identity_files" @change="isValid" />
              <label for="nidyes" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='nidno' type="radio" name="nidtype" class="btn-check" :value="0" v-model="nawData.new_identity_files" @change="isValid" />
              <label for="nidno" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>
          <template v-if="!isEditing || nawData.new_identity_files">
            <div class="row">
              <p class="w-40 pt-2">{{ $t('employee.id_type') }}</p>
              <select id="doc-type" class="w-60" v-model="nawData.identification_nmbrs_document_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="documentType in documentTypes" :key="documentType.id" :value="documentType.id">{{ documentType.description }}</option>
              </select>
            </div>
            <div class="row">
              <p class="w-40 pt-2">{{ $t('employee.id_type_enddate') }}</p>
              <input class="w-60" type="date" id="dob2" v-model="nawData.identification_expiration_date" data-notinpast="" @change="isValid" />
            </div>
            <UploadModule :model="docTypeOne" title="Voorkant" description="van het identiteitsbewijs" type="pdf" :owner="'contractFile2'" ref="contractFileUpload2"
                          @addFile="(e) => addNAWFile(e, 0)" @removeFile="removeNAWFile" />
            <UploadModule :model="docTypeTwo" title="Achterkant" description="van het identiteitsbewijs" type="pdf" :owner="'contractFile3'" ref="contractFileUpload3"
                          @addFile="(e) => addNAWFile(e, 1)" @removeFile="removeNAWFile" />
          </template>
        </div>



        <div v-show="currentNAWStep == 3" id="step-3">
          <p>{{ $t('employee.lhk_text') }}</p>
          <div class="row">
            <p class="col-sm-6 mb-0 pt-1">{{ $t('employee.lhk_question' + (isEmployeeFillingInNAW ? '' : '_2')) }}</p>
            <fieldset class="col-sm-6 btn-group" :data-field="contractData.job_function.tax_compensation">
              <input id='lhk1' type="radio" name="lhk" class="btn-check" :value="1" v-model="contractData.job_function.tax_compensation" @change="isValid($event); nawData.tax_compensation = 1" />
              <label for="lhk1" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='lhk2' type="radio" name="lhk" class="btn-check" :value="0" v-model="contractData.job_function.tax_compensation" @change="isValid($event); nawData.tax_compensation = 0" />
              <label for="lhk2" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>
          <br />
          <template v-if="nawData.tax_compensation && isEmployeeFillingInNAW">
            <p>U kunt hieronder uw handtekening zetten.</p>
            <div class="perfect-signature">
              <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad" @onEnd="sign()" />
            </div>
            <button class="btn action-button ms-2" @click="clear('main')">{{$t('form.reset')}}</button>
          </template>
          <div v-show="!isEmployeeFillingInNAW">
            <p>{{ $t(contractData.job_function.tax_compensation ? 'employee.lhv_employer_mandatory' : 'employee.lhv_employer') }}</p>
            <UploadModule :model="docTypeThree" title="Loonheffingsverklaring" description="kunt u hier uploaden" type="pdf" :owner="'contractFile4'" ref="contractFileUpload4"
                          @addFile="(e) => addNAWFile(e, 2)" @removeFile="removeNAWFile" />
          </div>
        </div>



        <div v-show="currentNAWStep == 4" id="step-4">
          <template v-if="isEmployeeFillingInNAW">
            <h1>{{ $t('employee.naw_ready_save') }}</h1>
            <p>{{ $t('employee.naw_ready_save_sub') }}</p>
          </template>
          <template v-else>
            <h1>{{ $t('employee.step_naw_4') }}</h1>
            <p>De medewerker is toegevoegd.</p>
          </template>
        </div>



        <div v-show="currentNAWStep == 5" id="step-5">
          <h1>{{ $t('employee.naw_thank_you') }}</h1>
          <p>{{ $t('employee.naw_thank_you_sub') }}</p>
        </div>
        
      </div>



      <div class="col-5 p-4">
        <div class="position-sticky">
          <h2>{{ $t((isEditing ? 'form.edit' : 'form.add'), [$t('navigation.employees')]) }}</h2>
          <ol>
            <li :class="getListClass(1)">{{ $t('employee.step_naw_1') }}</li>
            <li :class="getListClass(2)">{{ $t('employee.step_naw_2') }}</li>
            <li :class="getListClass(3)" v-if="!isEditing">{{ $t('employee.step_naw_3') }}</li>
            <li :class="getListClass(4)" v-if="!isEditing">{{ $t('employee.step_naw_4') }}</li>
          </ol>
        </div>
      </div>
    </div>



    <div class="row justify-content-between">
      <div class="col-6 text-start" v-if="isEmployeeFillingInNAW">
        <button type="button" class="btn px-5 secondary-button" @click="currentNAWStep--" v-if="currentNAWStep > 1 && currentNAWStep <= maxNAWStep" :disabled="addingFiles">{{ $t('form.back') }}</button>
      </div>
      <div class="col-6 text-start" v-else>
        <button type="button" class="btn px-5 secondary-button" @click="currentNAWStep--" v-if="currentNAWStep > 1" :disabled="addingFiles">{{ $t('form.back') }}</button>
        <button type="button" class="btn px-5 secondary-button" v-else-if="!isEditing" @click="goBack" :disabled="addingFiles">{{ $t('form.back') }}</button>
      </div>
      <div class="col-6 text-end">
        <template v-if="isEditing">
          <button type="button" class="btn px-5 secondary-button" @click="sendNAWCorrections" v-if="currentNAWStep == 1 && nawData.employee_status_id == 3">{{ $t('employee.save_send_mail') }}</button>
          <button type="button" class="btn px-5 action-button ms-2" @click="setNext" v-if="currentNAWStep == 1" :disabled="isLoading || addingFiles">{{ $t('form.next') }}</button>
          <button type="button" class="btn px-5 btn-success" @click="saveSection(2, false)" v-else :disabled="sendingData || addingFiles"> 
            <i class="fa fa-spinner fa-spin me-2" v-show="sendingData"></i> {{ $t('form.save') }}
          </button>
        </template>
        <button type="button" class="btn px-5 btn-success" @click="saveSection(2, false)" v-else-if="currentNAWStep == maxNAWStep" :disabled="sendingData || addingFiles"> 
            <i class="fa fa-spinner fa-spin me-2" v-show="sendingData"></i> {{ $t('form.save') }}
        </button>
        <button type="button" class="btn px-5 action-button" @click="setNext" v-else-if="currentNAWStep <= maxNAWStep" :disabled="isLoading || addingFiles">{{ $t('form.next') }}</button>
        <button type="button" class="btn px-5 action-button" v-else-if="!isEmployeeFillingInNAW" @click="backToOverview">{{ $t('employee.back_to_overview') }}</button>
      </div>
    </div>
  </section>

</template>

<script>
  import UploadModule from '@/components/UploadModule'
  import VPerfectSignature from "v-perfect-signature"
  import contractService from '@/services/ContractService'
  import employeeService from '@/services/EmployeeService'
  import fileService from '@/services/FileService'
  import IBAN from "iban";
  import moment from 'moment'
  import store from '@/store/user'
  import router from '@/router'
  export default {
    name: 'EditNAW',
    props: ['visible', 'contractObject', 'nawObject', 'nationalities', 'countries', 'documentTypes'],
    components: {
      UploadModule,
      VPerfectSignature
    },
    computed: {
      nawData: { get() { return this.nawObject } },
      contractData: { get() { return this.contractObject } },
    },
    data() {
      return{
        isEditing: false,
        isLoading: false,
        currentEmployeeStep: 1,
        currentNAWStep: 1,
        maxNAWStep: 3,
        docTypeOne: null,
        docTypeTwo: null,
        docTypeThree: null,
        isEmployeeFillingInNAW: false,
        sendDirectlyToNMBRS: false,
        sendingData: false,
        addingFiles: false,
        mail: { email_to: "", subject: "", email_body: "" },
        strokeOptions: {
          size: 4,
          thinning: 0.75,
          smoothing: 0.5,
          streamline: 0.5,
        }
      }
    },
    methods: {
      isValid(e){
        let el = e.target ? e.target : e;
        let shouldShowvalidity = true;
        let validityCheck = true;
        if(el.dataset.field){
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.dataset.field){
          el = el.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.parentElement.dataset.field){
          el = el.parentElement.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.tagName === "SELECT" && el.value === this.$t('form.select')){
          validityCheck = false;
        }
        else{
          switch(el.type){
            case "email":
              //eslint-disable-next-line
              validityCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(el.value);
              break;
            case "tel":
              validityCheck = /^\+?\d{8,13}$/.test( el.value.replaceAll(" ", "").replaceAll("-", "") );
              break;
            case "number":
              validityCheck = /^[0-9,.]+$/.test(el.value);
              break;
            case "date":
              validityCheck = /^\d{4}-\d{2}-\d{2}$/.test(el.value);
              if(validityCheck && (el.hasAttribute('data-notinpast') || el.hasAttribute('data-notinfuture'))){
                const date = new Date(el.value);
                if(el.hasAttribute('data-notinpast'))
                  validityCheck = date > Date.now();
                else
                  validityCheck = date < Date.now();
              }
              break;
            case "file":
              break;
            default: {
              switch(el.id){
                case "postal-code":
                    if (this.nawData.address && (this.nawData.address.country_id == 166)) {
                      validityCheck = /^\d{4}\s?[a-zA-Z]{2}$/.test(el.value);
                    } else {
                      validityCheck = el.value;
                    }
                  break;
                case "bsn":
                  validityCheck = this.validateBSN(el);
                  shouldShowvalidity = false;
                  break;
                case "iban":
                  validityCheck = this.validateIBAN(el);
                  shouldShowvalidity = false;
                  break;
                default:
                  validityCheck = el.value;
                  break;
              }
            }
          }
        }

        if(shouldShowvalidity)
          this.showValidity(el, validityCheck);
        return validityCheck;
      },
      validate(){
        const step = this.currentNAWStep;
        const els = document.querySelectorAll(`#step-${step} input:not(.optional):not([type="radio"]), #step-${step} select:not(.optional), #step-${step} fieldset:not(.optional)`);
        let isValid = true;
        els.forEach(el => {
          if(!this.isValid(el)){
            isValid = false;
          }
        });
        if(!isValid){
          alert(this.$t('errors.empty_fields'));
        }
        else if(this.isEmployeeFillingInNAW){
          if(this.currentNAWStep == 2 && ( !this.nawData.files[0] )){
            alert(this.$t('errors.identity'));
            isValid = false;
          }
          else if(this.currentNAWStep == 3 && this.nawData.tax_compensation && !this.nawData.tax_compensation_signature ){
            alert(this.$t('errors.signature_missing'));
            isValid = false;
          }
        }
        else{
          if(this.currentNAWStep == 2 && !this.nawData.files[0] && (!this.nawData.files || !this.nawData.files[0] ) && (!this.isEditing || this.nawData.new_identity_files)){
            alert(this.$t('errors.identity'));
            isValid = false;
          }
          else if(this.currentNAWStep == 3 && this.contractData.job_function.tax_compensation && !this.nawData.files[2] ){
            alert(this.$t('errors.lhk'));
            isValid = false;
          }
        }
        return isValid;
      },
      setNext(){
        if(!this.validate()) return;

        if(this.isEmployeeFillingInNAW){
          this.currentNAWStep++;
          this.maxNAWStep = 4;
        }
        else{
          if(!this.nawData.company_id) this.nawData.company_id = store.getters.getCurrentCompanyId;
          if(this.currentNAWStep == this.maxNAWStep){
            this.sendNAWData(2);
          }
          this.currentNAWStep++;
        }

        window.scrollTo(0, 0);
      },
      sendNAWData(nextFormStep){
        this.sendingData = true;
        if(this.isEmployeeFillingInNAW){
          this.sendNAWDataWhenAsEmployee();
          return;
        }
        this.nawData.country_id = this.nawData.address.country_id;
        if(this.nawData.files){
          this.nawData.files = this.setFiles(this.nawData.files);
        }
        this.nawData.bsn_number = ("000000000" + this.nawData.bsn_number).slice(-9);
        if(this.sendDirectlyToNMBRS){
          console.log('success');
          const data = {};
          data.address = {...this.nawData.address};
          for(let field in this.nawData){
            if(field == 'address' || field == 'contract') continue;
            data[field] = this.nawData[field];
          }
          data.external_id = this.nawData.external_id;
          if(!data.external_id){
            this.$toast.error("ID Bedrijf kan niet worden opgehaald");
            return;
          }
          if(!data.address.prefix) data.address.prefix = null;
          if(!data.identification_number) data.identification_number = null;
          if(!data.files.length) delete data.files;
          employeeService.updateNMBRS(store.getters.getCurrentCustomerId, data).then(() => {
            window.location.href = window.location.origin + "/employees";
          }).catch(this.toastError).finally(() => this.sendingData = false);
        }
        else if(this.nawData.id){
          employeeService.update(this.nawData).then(() => {
            this.sendingData = false;
            if(nextFormStep) {
              if (this.contractData.generate_contract === 0 && nextFormStep == 3) {
                window.location.href = window.location.origin + "/employees";
              }
              this.$parent.formSection = nextFormStep;
            } else {
              window.location.href = window.location.origin + "/employees";
            }
          }).catch(this.toastError).finally(() => this.sendingData = false);
        }
        else{
          employeeService.store(this.nawData).then(() => {
            this.sendingData = false;
            if(nextFormStep) {
              if (this.contractData.generate_contract === 0 && nextFormStep == 3) {
                window.location.href = window.location.origin + "/employees";
              }
              this.$parent.formSection = nextFormStep;
            } else {
              window.location.href = window.location.origin + "/employees";
            }
          }).catch(this.toastError).finally(() => this.sendingData = false);
        }
      },
      sendNAWDataWhenAsEmployee(){
        let nawData = {...this.nawData};
        nawData = {
          employee: nawData, 
          tax_compensation: this.nawData.tax_compensation, 
          tax_compensation_date: this.nawData.tax_compensation_date, 
          tax_compensation_signature: this.nawData.tax_compensation_signature
        };
        if (nawData.employee.address && nawData.employee.address.birth_place) {
          nawData.employee.birth_place = nawData.employee.address.birth_place;
        }
        if (nawData.employee.address && nawData.employee.address.country_id) {
          nawData.employee.country_id = nawData.employee.address.country_id;
        }
        if(nawData.files){
          nawData.files = this.setFiles(nawData.files);
        }
        if(this.token){
          nawData.employee.employee_status_id = 4;
          employeeService.updateWithToken(this.token, nawData).then(() => {
            this.currentNAWStep++;
            this.sendingData = false;
          }).catch(error => {
            this.toastError(error)
            console.log('error', error)
          }).finally(() => this.sendingData = false);
        }
        else if(nawData.id){
          employeeService.update(nawData).then(() => {
            this.currentNAWStep++;
          }).catch(error => {
            this.toastError(error)
            console.log('error', error)
          }).finally(() => this.sendingData = false);
        }
        else{
          console.log('error');
        }
      },
      getListClass(step){
        const currentStep = this.currentNAWStep;
        return step < currentStep ? "check" : (step == currentStep ? "active" : "inactive");
      },
      getSumBSN(bsn) {
        let a = parseInt(bsn[0]) * 9;
        let b = parseInt(bsn[1]) * 8;
        let c = parseInt(bsn[2]) * 7;
        let d = parseInt(bsn[3]) * 6;
        let e = parseInt(bsn[4]) * 5;
        let f = parseInt(bsn[5]) * 4;
        let g = parseInt(bsn[6]) * 3;
        let h = parseInt(bsn[7]) * 2;
        let i = parseInt(bsn[8]) * -1;

        let sum = a + b + c + d + e + f + g + h + i;
        return sum;
      },
      setMailInfo(e){
        this.currentEmployeeStep++;
        this.mail.subject = "Uitnodiging " + this.getCompany();
        this.mail.email_body = 
`Beste ${this.contractData.employee.first_name} ${this.contractData.employee.prefix ? (this.contractData.employee.prefix + ' ' + this.contractData.employee.last_name) : this.contractData.employee.last_name},

Voordat we jouw salaris kunnen betalen hebben wij jouw persoonlijke gegevens nodig.
Via onderstaande knop kun je deze snel en gemakkelijk aanleveren. 
Wij gaan vertrouwelijk met de gegevens om en slaan deze alleen op in jouw personeelsdossier.
Let op: zolang jij geen gegevens hebt aangeleverd, kunnen wij jou niet verlonen.

Bedankt!`
      },
      sendNAWCorrections(){
        const dataToSend = {...this.nawData};
        dataToSend.birth_date = "";
        employeeService.update(dataToSend).then((response) => {
          this.$parent.formSection = 1;
          this.currentEmployeeStep = 9;
          this.$parent.newcontract.sendingCorrections = true;
          this.contractData = {id: response.data.contract.id, employee: {first_name: dataToSend.first_name, prefix: dataToSend.prefix,  last_name: dataToSend.last_name}, job_function: {}};
          this.mail.email_to = dataToSend.email;
          this.setMailInfo();
        }).catch(this.toastError).finally(() => this.sendingData = false);
      },
      saveSection(section, nextFormStep){
        if(!this.validate()) return;
        this.sendNAWData(nextFormStep);
      },
      clear() {
        this.$refs.signaturePad.clear();
      },
      sign() {
        this.nawData.tax_compensation_signature = this.$refs.signaturePad.toDataURL();
        this.nawData.tax_compensation_date = moment().toISOString().split('T')[0];
        this.nawData.tax_compensation = 1;
      },
      showValidity(el, isValid, showCheckmark = false){
        if(isValid){
          if(showCheckmark)
            el.classList.add('is-valid');
          el.classList.remove('is-invalid');
        }
        else{
          if(showCheckmark)
            el.classList.remove('is-valid');
          el.classList.add('is-invalid');
        }
      },
      validateBSN(userBSN) {
        let bsn = ("000000000" + userBSN.value).slice(-9);

        let valid = true;
        if (!userBSN.value || isNaN(userBSN.value) || Number(userBSN.value) == 0 || this.getSumBSN(bsn) % 11)
          valid = false;

        this.showValidity(userBSN, valid, true);
        return valid;
      },
      validateIBAN(userIBAN) {
        this.nawData.iban = this.nawData.iban ? this.nawData.iban.toUpperCase().replaceAll(/[^A-Z0-9]/g, '') : '';
        let valid = userIBAN.value && IBAN.isValid(this.nawData.iban);
        this.showValidity(userIBAN, valid, true);
        return valid;
      },
      getCompany(){
        const name = store.getters.getCurrentCompanyName;
        if(!name){
          const el = document.getElementById('company-select');
          if(!el || el.selectedIndex === -1) return '-';
          return el.options[el.selectedIndex].innerHTML;
        }
        return name;
      },
      getHoursCleaned(value){
        const rvalue = value.toFixed(2).replace('.', ',').replace(',00', '');
        return rvalue[rvalue.length - 1] === '0' && rvalue.includes(',') ? rvalue.slice(0, -1): rvalue;
      },
      downloadPDF(item) {
        this.item = {...item};
        contractService.download(this.item.id).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const companyName = this.getCompany().replaceAll(' ', '-');
            const pdfName = companyName + "_" + item.employee.first_name.replace(' ', '-') + '_' + (item.employee.prefix ? (item.employee.prefix + '_') : '') + item.employee.last_name.replace(' ', '-') + "_" + item.generate_date;
            link.setAttribute("download", pdfName + ".pdf");
            document.body.appendChild(link);
            link.click();
        }).catch(this.toastError);
      },
      backToOverview(){
        this.$router.push({ name: "employees" });
      },
      addNAWFile(file, index){
        this.addingFiles = true;
        const ref = this.$refs[`contractFileUpload${index + 2}`];
        ref.setWaiting(true);
        fileService.store(file).then(response => {
          this.nawData.files[index] = response.data;
          this.addingFiles = false;
          ref.setWaiting(false);
        }).catch((error) => {
          this.toastError(error);
          this.addingFiles = false;
          ref.setWaiting(false);
        });
      },
      removeNAWFile(index){
        this.nawData.files[index] = null;
      },
      setFiles(fileData){
        let files = fileData;
        const size = files.length - 1;
        for(let i = size; i >= 0; i--){
          if(files[i] == null) files.splice(i, 1);
        }
        return files;
      },

      goBack(){
        this.$parent.formSection = 1;
      }
    },
  }
</script>